import { useEffect, useMemo, useState } from 'react'
import {noop} from '../utils/noop'
import type { ITerminalOptions, Terminal } from 'xterm'
import 'xterm/css/xterm.css'

const terminalOptions: ITerminalOptions = {
    convertEol: true,
    cursorBlink: true,
    fontFamily: 'Source Code Pro',
    letterSpacing: -0.5,
}

export function useTerminal(element: HTMLDivElement, MOTD = "") {
    const [stateTerminal, setTerminal] = useState<Terminal | null>(null)

    useEffect(() => {
        if (typeof document == "undefined") return noop
        if (!element) return noop

        // imports are lazy-loaded
        const {Terminal} = require('xterm')
        const {WebLinksAddon} = require('xterm-addon-web-links')
        const {FitAddon} = require('xterm-addon-fit')

        // create terminal and addons
        const terminal = new Terminal(terminalOptions) as Terminal
        const webLinksAddon = new WebLinksAddon()
        const fitAddon = new FitAddon()

        // bind addons
        terminal.loadAddon(webLinksAddon)
        terminal.loadAddon(fitAddon)
        // terminal['setOption']('logLevel', 'debug')
        
        // fit terminal to parent element
        fitAddon.fit()
        terminal.open(element)
        fitAddon.fit()

        setTerminal(terminal)
        
        if (MOTD) {
            terminal.write(MOTD)
        }

        // focus the terminal window
        terminal.focus()

        // handle window resizes
        const onWindowResize = () => fitAddon.fit()
        window.addEventListener('resize', onWindowResize)

        return () => {
            window.removeEventListener('resize', onWindowResize)
            terminal?.dispose()
            setTerminal(null)
        }
    }, [element, MOTD])

    return stateTerminal
}
